<template>
    <section class="pt-3 px-3">
        <div class="row mx-0">
            <div class="col px-0 bg-white br-t-12 mx-2">
                <div class="row mx-0 align-items-center border-bottom" style="height:50px;">
                    <div class="bg-general3 cr-pointer text-white d-middle-center rounded-circle" style="width:25px;height:25px;" @click="$router.back()">
                        <i class="icon-left f-18" />
                    </div>
                    <div class="col-auto f-22 text-general f-600">
                        Rayo McQueen
                    </div>
                    <div class="btn-ruta ml-auto d-middle-center cr-pointer border br-8" @click="editarDriver">
                        <i class="icon-pencil-outline" />
                    </div>
                    <div class="btn-ruta d-middle-center ml-2 cr-pointer border br-8" @click="eliminarDriver">
                        <i class="icon-delete-outline" />
                    </div>
                </div>
                <div class="custom-scroll overflow-auto pt-4" style="height:calc(100vh - 125px)">
                    <div class="row mx-0">
                        <div class="col-auto">
                            <img :src="url_persona" class="obj-cover br-6" width="162" height="162" />
                        </div>
                        <div class="col">
                            <div class="row mx-0 align-items-center">
                                <i class="icon-truck text-general f-20" />
                                <div class="col-auto text-general">
                                    <p>GYY 487</p>
                                    <p>Vehículo</p>
                                </div>
                                <el-tooltip placement="bottom" content="Servicios del vehiculo" effect="light">
                                    <div class="btn-ruta d-middle-center ml-2 border br-8" @click="serviciosVehiculo">
                                        <i class="icon-info-circled-alt" />
                                    </div>
                                </el-tooltip>
                            </div>
                            <p class="text-general mt-3">
                                <b>Número de documento:</b>
                                102345678
                            </p>
                            <p class="text-general mt-3">
                                <b>Teléfono celular</b>
                                300 123 4567
                            </p>
                            <p class="text-general mt-3">
                                <b>id_cargamos</b>
                                65796413216
                            </p>
                        </div>
                    </div>
                    <div class="row mx-0 py-4" />
                    <div class="row mx-0 text-general f-600">
                        <div class="col-auto">
                            Calificación
                        </div>
                        <div class="col-auto">
                            4.8 <i class="icon-star text-warning ml-2 f-20" />
                        </div>
                    </div>
                    <div class="row mx-0 py-4" />
                    <div class="row mx-0">
                        <div class="col-12 mb-3 text-general f-17 f-600">
                            Entregas realizadas y programadas
                        </div>
                        <div class="col bg-tema text-center py-2 br-12 px-0 mx-1 text-general f-15">
                            Última semana 
                            <b class="ml-2">
                                140
                            </b>    
                        </div>
                        <div class="col bg-tema text-center py-2 br-12 px-0 mx-1 text-general f-15">
                            Última semana 
                            <b class="ml-2">
                                24
                            </b>    
                        </div>
                        <div class="col bg-tema text-center py-2 br-12 px-0 mx-1 text-general f-15">
                            Última semana 
                            <b class="ml-2">
                                10
                            </b>    
                        </div>
                    </div>
                    <div class="row mx-0 py-4" />
                    <div class="row mx-0">
                        <div class="col-12 text-general f-17 f-600">
                            Entregas del último mes
                        </div>
                        <div class="col-12 mt-3 text-general text-center f-17 f-600">
                            <b>457 Pedidos</b> <span class="text-success f-600">+10%</span>
                        </div>
                        <div class="col-12">
                            <echart :options="calendario" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col px-0 bg-white br-t-12 mx-2">
                <historial v-if="!turno" @verHistorial="mostrarTurno" />
                <turno v-else @verTurno="mostrarTurno(false)" />
            </div>
        </div>
        <!-- Partials -->
        <modal ref="modalServicio" titulo="Servicios del vehículo">
            <div v-for="data in 6" :key="data" class="row mx-0 align-items-center justify-center my-2 ">
                <div class="col-auto f-20">
                    <i class="icon-truck text-general f-22" />
                    <span class="ml-2 text-general">JHY 784</span>
                </div>
                <div class="col-auto text-general f-20">
                    31
                </div>
            </div>
        </modal>
        <modal-eliminar ref="modalEliminar" titulo="Eliminar Driver" mensaje="¿Desea eliminar este driver?" />
        <modal-crear-driver ref="modalCrearDriver" />
    </section>
</template>

<script>

import Vue from 'vue'
import echarts from 'echarts'
Vue.component('echarts',echarts);

export default {
    components: {
        historial: () => import('./components/historial'),
        turno: () => import('./components/turno'),
        modalCrearDriver: () => import('../partials/modalCrearDriver')
    },
    data(){
        return{
            url_persona: 'https://concepto.de/wp-content/uploads/2018/08/persona-e1533759204552.jpg',
            calendario: {
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                        label: {
                            show: true
                        }
                    }
                },
                xAxis: {
                    data: [
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "8",
                        "9",
                        "0",
                        "1",
                        "2"
                    ],
                    axisLine: {
                        show: true, 
                        lineStyle: {
                            color: "#3d5269",
                            width: 1
                        }
                    },
                    axisTick: {
                        show: true,
                        alignWithLabel: true
                    },
                    axisLabel: {
                        show: false,
                        textStyle: {
                            color: "#396A87",
                            fontSize: 14
                        },
                        interval: 0,
                        rotate: 30
                    }
                },
                yAxis: [
                    {
                        type: "value",
                        nameTextStyle: {
                            color: "#396A87",
                            fontSize: 14
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                width: 1,
                                color: "#3d5269"
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: false
                        },
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: "#396A87",
                                fontSize: 14
                            }
                        }
                    },
                    {
                        type: "value",
                        nameTextStyle: {
                            color: "#396A87",
                            fontSize: 14
                        },
                        position: "right",
                        splitLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: "#396A87",
                                width: 2
                            }
                        },
                        axisLabel: {
                            show: true,
                            formatter: "{value} %",
                            textStyle: {
                                color: "#396A87",
                                fontSize: 14
                            }
                        }
                    }
                ],
                series: [
                    {
                        name: "今日实时人数",
                        type: "bar",
                        barWidth: 18,
                        itemStyle: {
                            normal: {
                                color: '#DBDBDB'
                            }
                        },
                        data: [133, 23, 114, 67, 89, 35, 67, 96, 90, 46, 75, 85]
                    }
                ]
            },
            turno: false
        }
    },
    methods: {
        serviciosVehiculo(){
            this.$refs.modalServicio.toggle();
        },
        eliminarDriver(){
            this.$refs.modalEliminar.toggle();
        },
        mostrarTurno(state){
            this.turno = state;
        },
        editarDriver(){
            this.$refs.modalCrearDriver.toggle();
        }
    }
}
</script>
<style lang="scss" scoped>
    .btn-ruta{
        width: 32px;
        height: 32px;
        background-image: linear-gradient(to bottom, #FFFFFF, #F3F5F7);
        color: var(--text-general) !important;
    }
    .bg-tema{
        background-color: #F6F9FB;
    }
</style>