<template>
    <section>
        <div class="row mx-0 align-items-center pt-3">
            <div class="col-auto text-general f-600 f-17">
                Drivers
            </div>
            <div class="col-auto ml-auto">
                <div class="btn-filtro border d-middle bg-white br-8 px-2 position-relative" @click="show_filter = !show_filter">
                    <span class="text-general f-14 tres-puntos">{{filter_active}}</span>
                    <i class="icon-swap-vertical-bold-up text-general f-15 ml-auto" />
                    <div v-if="show_filter" class="position-absolute f-14 bg-white br-6 shadow" style="width:164px;left:0;top:36px;z-index:5">
                        <p 
                        v-for="(filter, f) in filter_options" 
                        :key="f" 
                        class="pl-2 filter-option d-middle cr-pointer" 
                        @click="filter_active = filter.nombre"
                        >
                            {{ filter.nombre }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-auto px-2">
                <div class="bg-general cr-pointer text-white br-8 d-middle-center shadow px-5 f-14" style="height:32px;" @click="crearDriver">
                    Crear driver
                </div>
            </div>
            <div class="col-3">
                <el-input v-model="buscar" size="small" class="w-100" placeholder="Buscar" />
            </div>
        </div>
        <div class="custom-scroll overflow-auto pt-3">
            <div class="row mx-0">
                <router-link v-for="data in 20" :key="data" :to="{name: 'admin.rutas.ver-driver', params: {id_driver: 1} }">
                    <div class="card-driver cr-pointer br-12 bg-white position-relative my-2 mx-2">
                        <div class="bg-general3 f-15 rounded-circle position-absolute" style="right:8px;top:5px;">
                            <i class="icon-truck text-white" />
                        </div>
                        <img :src="url_persona" width="100%" height="162px" class="obj-cover br-t-12" />
                        <p class="pl-2  mt-1 tres-puntos f-16 text-general f-500">Rayo McQueen</p>
                        <p class="pl-2 f-15 tres-puntos text-general"><i class="icon-truck f-18 text-general" />GYY 487 </p>
                        <p class="pl-2 f-15 tres-puntos text-general my-1"> 
                            <b>4.7</b> 
                            <i class="icon-star f-18 text-warning" />
                        </p>
                        <div class="row mx-0 pl-2 mt-2">
                            <i class="icon-historial text-general f-20" />
                            <div class="col tres-puntos pl-0 text-general">
                                <p class="f-15 tres-puntos">
                                    10 Oct. 2021
                                </p>
                                <p class="f-15 tres-puntos mt-2">
                                    10 Servicios
                                </p>
                                <p class="f-13 text-general tres-puntos">Último año</p>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
        <!-- Partials -->
        <modal-crear-driver ref="modalCrearDriver" />
    </section>
</template>

<script>


export default {
    components: {
        modalCrearDriver: () => import('../partials/modalCrearDriver')
    },
    data(){
        return{
            buscar: '',
            show_filter: false,
            filter_options: [
                { id: 1, nombre: 'Nombre' },
                { id: 2, nombre: 'Cantidad de pedidos' },
                { id: 3, nombre: 'Servicio más reciente' },
                { id: 3, nombre: 'Calificación' }
            ],
            filter_active: 'Nombre',
            url_persona: 'https://concepto.de/wp-content/uploads/2018/08/persona-e1533759204552.jpg'
        }
    },
    methods: {
        crearDriver(){
            this.$refs.modalCrearDriver.toggle()
        }
    }
}
</script>
<style lang="scss" scoped>
.btn-filtro{
    width: 164px;
    height: 32px;
}
a{
    text-decoration: none !important;
}
.filter-option{
    height: 26px;
    &:hover{
        background-color: #F6F9FB;
    }
}
.card-driver{
    width: 162px;
    height: 341px;
    box-shadow: 0px 1px 4px #00000014;
}
</style>