<template>
    <section class="pt-3">
        <div class="row mx-0 align-items-center">
            <div class="col-auto text-general f-600 f-17">
                Lista turnos
            </div>
            <div class="col-auto px-2 ml-auto">
                <div class="bg-general br-8 d-middle-center px-4 text-white cr-pointer" style="height:32px;" @click="crearTurno()">
                    Crear turno
                </div>
            </div>
            <div class="col-3">
                <el-input v-model="buscar" class="w-100 br-20" placeholder="Buscar" clearable @change="value => filtro(value)" />
            </div>
        </div>
        <div class="custom-scroll overflow-auto pt-3" style="height:calc(100vh - 190px)">
            <div v-for="(turno, t) in turnos" :key="t" class="row mx-2 cr-pointer align-items-center bg-white shadow br-4 py-3 my-2" @click="$router.push({name: 'admin.rutas.turno.ver', params: {id_turno: turno.id}})">
                <div class="col-auto text-general f-18 text-center">
                    No. {{ turno.id }}
                </div>
                <div class="col text-center text-general">
                    <i class="icon-fecha-fin f-18 text-general" />
                    {{ formatearFecha(turno.fecha_dia) }}
                </div>
                <div class="col text-general">
                    <i class="icon-clock text-general f-18" />
                    {{ formatearFecha(`${turno.fecha_dia} ${turno.fecha_hora_inicio}`,'hh:mma') }} - {{ formatearFecha(`${turno.fecha_dia} ${turno.fecha_hora_fin}`,'hh:mma') }}
                </div>
                <div class="col-auto text-general text-center">
                    <i class="icon-route f-18" />
                    {{ agregarSeparadoresNumero(turno.rutas_count) }}
                </div>
                <div class="col text-center text-general">
                    <i class="icon-pedidos f-18" />
                    {{ agregarSeparadoresNumero(turno.pedidos) }}
                </div>
                <div class="col">
                    <template v-if="turno.driver">
                        <img :src="turno.driver.imagen" width="34" height="34" class="obj-cover rounded-circle" />
                        <span class="text-general f-15 ml-2" v-text="turno.driver.nombre" />
                    </template>
                </div>
                <div class="col-auto justify-content-end">
                    <div :class="`border-radius br-12 justify-center px-3 d-flex ${estadoTurno(turno.estado)}`" style="min-width:111px;">
                        <i :class="`icon-${iconoTurno(turno.estado)} mr-2`" />
                        <p class="tres-puntos" v-text="textoTurno(turno.estado)" />
                    </div>
                </div>
            </div>
            <div v-if="cantidad != turnosCountTotal" class="row mx-0 justify-center py-2">
                <div class="col-auto">
                    <div class="bg-general3 text-white cr-pointer br-20 px-4 f-14 py-1" @click="listarTurnos(10)">
                        Ver más 10
                    </div>
                </div>
                <div class="col-auto">
                    <div class="bg-general3 text-white cr-pointer br-20 px-4 f-14 py-1" @click="listarTurnos(50)">
                        Ver más 50
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-editar-turno ref="modalEditarTurno" />
    </section>
</template>

<script>
import Turnos from "~/services/routing/turnos";
import { mapGetters } from 'vuex'

export default {
    components: {
        modalEditarTurno: () => import('./partials/modalEditarTurno')
    },
    data(){
        return {
            buscar: null,
            turnos: [],
            turnosCountTotal: 0,
            turnosCount: 0,
            cantidad: 0,
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),

    },
    watch: {
        id_moneda(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.listarTurnos()
        }
    },
    mounted(){
        this.montar()
    },
    methods: {
        async montar(){
            try {
                this.listarTurnos()
            } catch (error){
                this.error_catch(error)
            }finally{
            }
        },
        async listarTurnos(page = null){
            try {
                const { id: lastId = null } = _.last(this.turnos) ?? {}
                const params = {
                    page,
                    lastId
                }
                const { data } = await Turnos.listarTurnos(params)
                this.turnos = [...this.turnos, ...data.turnos]
                this.turnosCountTotal = data.turnosCountTotal
                this.turnosCount = data.turnosCount
                this.cantidad += data.turnosCount
            } catch (error){
                this.error_catch(error)
            }
        },
        async filtro(query = null){
            try {
                const params = {
                    query,
                }
                const { data } = await Turnos.listarTurnos(params)

                this.limpiar()
                this.turnos = [...this.turnos, ...data.turnos]
                this.turnosCountTotal = data.turnosCountTotal
                this.turnosCount = data.turnosCount
                this.cantidad += data.turnosCount
            } catch (error){
                this.error_catch(error)
            }
        },
        limpiar(){
            this.turnos = []
            this.turnosCountTotal = 0
            this.turnosCount = 0
            this.cantidad = 0
        },
        estadoTurno(state){
            switch (state){
            case 1:
                return 'bg-light-f5 text-general';
            case 2:
                return 'solicitado';
            case 3:
                return 'curso';
            case 4:
                return 'finalizado';
            }
        },
        iconoTurno(state){
            switch (state){
            case 1:
                return 'playlist-edit';
            case 2:
                return 'alert-triangle';
            case 3:
                return 'truck';
            case 4:
                return 'ok-circled-outline';
            }
        },
        textoTurno(state){
            switch (state){
            case 1:
                return 'Borrador';
            case 2:
                return 'Solicitado';
            case 3:
                return 'En Curso';
            case 4:
                return 'Finalizado';
            }
        },
        crearTurno(){
            this.$refs.modalEditarTurno.toggle();
        }
    }
}
</script>
<style lang="scss" scoped>
.solicitado{
    background-color: #FF8B32;
    color: #FFFFFF;
    border-radius: 12px;
}
.curso{
    background-color: #2196F3;
    color: #FFFFFF;
    border-radius: 12px;
}
.finalizado{
    background-color: #27D07B;
    color: #FFFFFF;
    border-radius: 12px;
}
</style>
